import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/LoginView.vue'),
        meta: {
            title: '登录',
            isAuth: false,
            favicon: 'login.svg' // 登录页面的自定义 favicon
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/LoginView.vue'),
        meta: {
            title: '登录',
            isAuth: false,
            favicon: 'login.svg' // 登录页面的自定义 favicon
        },
    },
    {
        path: '/app',
        component: () => import('@/components/MainLayout.vue'), // 使用共享布局组件
        children: [
            {
                path: 'gallery',
                name: 'Gallery',
                component: () => import('@/views/GalleryView.vue'),
                meta: {
                    title: 'Midjourney国内版绘画-画廊',
                    isAuth: true
                }
            },
            {
                path: 'mj',
                name: 'Channels',
                component: () => import('@/views/ChannelsView.vue'),
                meta: {
                    title: 'Midjourney国内版绘画-MJ',
                    isAuth: true
                }
            },
            {
                path: 'chat',
                name: 'Chat',
                component: () => import('@/views/chat/ChatIndex.vue'),
                meta: {
                    title: 'Midjourney国内版绘画-Chat',
                    isAuth: true
                }
            },
            {
                path: 'suno',
                name: 'Suno',
                component: () => import('@/views/suno/SunoIndex.vue'),
                meta: {
                    title: 'Midjourney国内版绘画-Suno',
                    isAuth: true
                }
            },
            {
                path: 'me',
                name: 'me',
                component: () => import('@/views/me/UserProfile.vue'),
                meta: {
                    title: 'Midjourney国内版绘画-个人',
                    isAuth: true
                }
            },
            {
                path: 'membership',
                name: 'membership',
                component: () => import('@/views/membership/MemberShip.vue'),
                meta: {
                    title: 'Midjourney国内版绘画-个人',
                    isAuth: true
                }
            }
        ]
    },
    {
        path: '/channels',
        name: 'Chat2',
        component: () => import('@/views/chat/ChatIndex.vue'),
        meta: {
            title: 'Discord',
            isAuth: false
        }
    },
];

const router = createRouter({
    history: createWebHistory(), // 如果需要在子目录部署，可以在此处指定 base 路径
    routes
});

// 路由守卫动态修改 favicon
router.beforeEach((to, from, next) => {
  // 使用类型断言指定 `to.meta.favicon` 为 `string | undefined`
  const favicon = (to.meta.favicon as string) || 'favicon.svg';
  changeFavicon(favicon);
  next();
});

function changeFavicon(favicon: string): void {
  // 尝试获取已有的 favicon link 标签，并断言类型为 HTMLLinkElement
  let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");

  if (!link) {
      // 如果没有找到现有的 link 标签，就创建一个新的
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
  }
  // 设置新的 favicon 路径
  link.href = favicon;
}

export default router;
